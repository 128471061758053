import {
  CREATE_MESSAGE_INIT,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_ERROR,
} from '../actions/actionTypes';
import STATUS from '../actions/constants';

const initialState = {
  status: STATUS.IDLE,
  error: null,
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_MESSAGE_INIT:
      return {
        ...state,
        status: STATUS.LOADING,
        error: null,
      };
    case CREATE_MESSAGE_SUCCESS:
      return {
        ...state,
        status: STATUS.SUCCEEDED,
        error: null,
      };
    case CREATE_MESSAGE_ERROR:
      return {
        ...state,
        status: STATUS.FAILED,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default messagesReducer;
