import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';

import InputFormGroup from './InputFormGroup';
import TextFormGroup from './TextFormGroup';


import createMessage from '../../redux/actions/messageActions';
import STATUS from '../../redux/actions/constants';

const ContactForm = () => {
  const dispatch = useDispatch();
  const { status } = useSelector(state => state.messages);

  const [sendMessage, setSendMessage] = useState(false);

  const [name, setName] = useState('');
  const [nameValid, setNameValid] = useState(false);
  const [nameTouched, setNameTouched] = useState(false);

  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);

  const [subject, setSubject] = useState('');
  const [subjectValid, setSubjectValid] = useState(false);
  const [subjectTouched, setSubjectTouched] = useState(false);

  const [description, setDescription] = useState('');
  const [descriptionValid, setDescriptionValid] = useState(false);
  const [descriptionTouched, setDescriptionTouched] = useState(false);

  useEffect(() => {
    if (sendMessage) {
      dispatch(createMessage({
        name,
        email,
        subject,
        description
      }));
    } else if (!sendMessage) {
      resetForm();
    }
  }, [sendMessage])

  const setState = {
    name: (name) => setName(name),
    email: (email) => setEmail(email),
    subject: (subject) => setSubject(subject),
    description: (description) => setDescription(description),
  }

  const resetForm = () => {
    setName('');
    setEmail('');
    setSubject('');
    setDescription('');
    setNameTouched(false);
    setNameValid(false);
    setEmailTouched(false);
    setEmailValid(false);
    setSubjectTouched(false);
    setSubjectValid(false);
    setDescriptionTouched(false);
    setDescriptionValid(false);
  }

  const validateName = (updateState=true) => {
    const bool = name.trim() !== '' && name.trim().length > 2;
    if (updateState) {
      setNameTouched(true);
      setNameValid(bool);
    }
    return bool;
  }

  const validateEmail = (updateState=true) => {
    const bool = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    if (updateState) {
      setEmailTouched(true);
      setEmailValid(bool);
    }
    return bool;
  }

  const validateSubject = (updateState=true) => {
    const bool  = subject.trim() !== '' && subject.trim().length > 2;
    if (updateState) {
      setSubjectTouched(true);
      setSubjectValid(bool);
    }
    return bool;
  }

  const validateDescription = (updateState=true) => {
    const bool = description.trim() !== '' && description.trim().length > 2;
    if (updateState) {
      setDescriptionTouched(true);
      setDescriptionValid(bool);
    }
    return bool;
  }

  const validators = {
    name: (name) => validateName(),
    email: (email) => validateEmail(),
    subject: (subject) => validateSubject(),
    description: (description) => validateDescription(),
  }

  const handleChange = (e) => {
    const { name, value: newValue } = e.target;
    setState[name](newValue);
  }

  const handleBlur = (e) => {
    const { name } = e.target;
    validators[name]();
  }

  const validForm = () => {
    Object.keys(validators).forEach((key) => {
      if (!validators[key]()) return false
    });
    return true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validForm()) {
      setSendMessage(true);
    }
  }

  if (status === STATUS.SUCCEEDED && sendMessage) {
    setSendMessage(false)
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <InputFormGroup
        name="name"
        value={name}
        label="Your Name *"
        handleChange={handleChange}
        handleBlur={handleBlur}
        valid={nameValid}
        touched={nameTouched}
        invalidFeedback="Invalid name. Should be more than 2 characters"
      />

      <InputFormGroup
        name="email"
        value={email}
        label="Your Email *"
        handleChange={handleChange}
        handleBlur={handleBlur}
        valid={emailValid}
        touched={emailTouched}
        invalidFeedback="Email is invalid"
      />

      <InputFormGroup
        name="subject"
        value={subject}
        label="Write a Subject"
        handleChange={handleChange}
        handleBlur={handleBlur}
        valid={subjectValid}
        touched={subjectTouched}
        invalidFeedback="Invalid subject. Should be more than 2 characters"
      />

      <div className="form-group">
        <TextFormGroup
          name="description"
          value={description}
          label="Your Message"
          handleChange={handleChange}
          handleBlur={handleBlur}
          valid={descriptionValid}
          touched={descriptionTouched}
          invalidFeedback="Invalid Description. Should be more than 2 characters"
        />
      </div>
      <div className="layout horizontal">
        <button
          type="submit"
          className="theme-btn btn-theme-color layout horizontal mr--5"
        >
          Submit
        </button>
        {status === STATUS.LOADING && <Loader
            type="BallTriangle"
            color="#f9004d"
            height={30}
            width={30}
          />}
      </div>
    </form>
  );
}

export default ContactForm;
