import React from 'react';
import PropTypes from 'prop-types';

import '../common/css/projects.scss';

import Row from '../common/Row';
import Project from './Project';

const ProjectList = ({ projects }) => (
  <div className="projects-wrapper plr--10">
    <Row className="row--5">
      {projects.map((project) => (<Project key={project.id} project={project} />))}
    </Row>
  </div>
);

ProjectList.propTypes = {
  projects: PropTypes.array.isRequired,
};

export default ProjectList;
