import React from 'react';
import PropTypes from 'prop-types';
import Col from '../common/Column';
import Container from '../common/Container';
import Row from '../common/Row';
import Section from '../common/Section';

const HomeSection = ({
  title, subheader, children, className, showTitle, style,
}) => (
  <Section className={className} style={style}>
    {showTitle
    && (
      <Container>
        <Row>
          <Col className="col-lg-6 col-md-8 col-sm-10 offset-lg-3 offset-md-2 offset-sm-1">
            <div className="section-title text-center mb-60">
              <h2 className="center h2-big">
                Our
                <span>{` ${title}`}</span>
              </h2>
              <hr className="lines" />
              <p>
                {subheader}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    )}
    {children}
  </Section>
);

HomeSection.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  className: PropTypes.string,
  showTitle: PropTypes.bool,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]),
};

HomeSection.defaultProps = {
  children: <span />,
  className: '',
  showTitle: true,
  style: {},
};

export default HomeSection;
