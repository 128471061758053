import React from 'react';
import PropTypes from 'prop-types';

function Section({
  children,
  className,
  id,
  style,
}) {
  return (
    <section className={`section ${className}`} id={id} style={style}>
      {children}
    </section>
  );
}

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]),
};

Section.defaultProps = {
  children: <span />,
  className: '',
  id: '',
  style: {},
};

export default Section;
