import React from 'react';
import PropTypes from 'prop-types';

import Typist from 'react-typist';

function HeaderContentThree({ quote }) {
  const cursor = {
    show: false,
    blink: true,
    element: '|',
    hideWhenDone: false,
    hideWhenDoneDelay: 1000,
  };

  return (
    <div id="header-content-three">
      <Typist cursor={cursor}>
        <Typist.Delay ms={1000} />
        <h2 className="headline">
          <b>{quote.tagline}</b>
        </h2>
        <Typist.Delay ms={1250} />
        <p className="tagline">
          {`${quote.follow_up}!!!`}
        </p>
      </Typist>
    </div>
  );
}

HeaderContentThree.propTypes = {
  quote: PropTypes.shape({
    tagline: PropTypes.string.isRequired,
    follow_up: PropTypes.string.isRequired,
  }).isRequired,
};

export default HeaderContentThree;
