import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// redux
import STATUS from '../../redux/actions/constants';
import getProjects from '../../redux/actions/projectActions';

import HomeSection from './HomeSection';
import ProjectList from '../projects/ProjectList';
import ContainerLoader from '../common/ContainerLoader';
import Messenger from '../common/Messenger';

export default function ProjectSection() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProjects());
  }, []);

  const { status, projects } = useSelector((state) => state.projects);

  return (
    <HomeSection
      title="Projects"
      subheader="The following list provides a brief glimpse at GESCH's experience in conducting high profile, complex and sensitive EIA's, Resettlement Acton Plans and ESMP's among other projects"
      className="light-bg"
    >
      {status === STATUS.LOADING && (<ContainerLoader />)}
      {status !== STATUS.LOADING && projects && projects.length === 0 && (
        <Messenger message="Check back later when we have populated the list of projects." />
      )}
      {status !== STATUS.LOADING && projects && projects.length > 0 && (
        <ProjectList projects={projects} />
      )}
    </HomeSection>
  );
}
