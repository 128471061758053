import React, { useState } from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

const GESCH ='Gesch Consult Ltd';

const GeschMap = (props) => {
  const [activeMarker, setActiveMarker] = useState({});
  const [showingInfoWindow, setShowingInfoWindow] = useState({});

  const onMarkerClick = (props, marker, e) => {
    setActiveMarker(marker);
  }

  const { width, height, lat, lng} = props;
  return (
    <Map
      google={props.google}
      zoom={16}
      style={{ width, height, position: 'relative' }}
      initialCenter={{ lat, lng }}
    >
      <Marker
        name={GESCH}
        position={{ lat, lng }}
        onClick={onMarkerClick}
      />
      <InfoWindow
        marker={activeMarker}
        visible
        >
          <div>
            <h3>{GESCH}</h3>
          </div>
        </InfoWindow>
    </Map>
  )
};

GeschMap.defaultProps = {
  width: '100',
  height: '100%',
  lat: 0.34395905366337165,
  lng: 32.58179037088832,
};

export default GoogleApiWrapper({
  apiKey: (process.env.REACT_APP_GMAPS_API_KEY)
})(GeschMap);
