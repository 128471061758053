import React, { useState } from 'react';
import PropTypes from 'prop-types';

import iphoneUrl from '../../images/iphone.jpg';
import aboutUrl from '../../images/about.jpg';
import HomeSection from '../home/HomeSection';
import Container from '../common/Container';
import Row from '../common/Row';
import Col from '../common/Column';

const parallaxStyle = {
  backgroundImage: `url(${iphoneUrl})`,
  padding: 0,
};

const AboutIntro = ({ children }) => {
  const tabs = [
    {
      key: 'tab1',
      title: 'Our Mission',
      paragraphs: [
        `To be the benchmark of quality within this
        industry by being the most innovative and diversified environmental
        consulting firm in Africa.`,
      ],
      hasBullets: false,
      bulletHeader: 'Lorem ipsum dolor sit.',
      bullets: [],
    },
    {
      key: 'tab2',
      title: 'Our Vision',
      paragraphs: [
        `To be the leading firm in providing the best and most convenient
          environmental and social solutions as well as Health and Safety
          measures to our clients.`,
      ],
      hasBullets: false,
      bulletHeader: 'Lorem ipsum dolor sit.',
      bullets: [],
    },
    {
      key: 'tab3',
      title: 'Our Values',
      paragraphs: [
        `Our values reflect our drive to maintain excellent knowledge on the interaction between the community
          and environment, practicing sound financial and project management that produces optimal results, and
          importantly, exercising effective communication with our clients and stakeholders to our projects.
        `,
      ],
      hasBullets: true,
      bulletHeader: 'To influence organizations, systems and processes towards environmental and social preeminent practice, here are our values',
      bullets: [
        `Integrity: We are committed to be truthful, honest and authentic for every work we do.`,
        `Professionalism: We are competent and determined, not only to exceed our standards but
        also produce greater efficiencies in our work and greater participation with our community.`,
        `Client satisfaction: We are dedicated to achieving customer satisfaction and building strong
        client relationships. Our clients are the lifeblood of our business and our most important stakeholder.
        We strive to meet or exceed their expectations for every work we offer.`,
        `Flexibility: Since we provide environmental and social solutions, we are devoted to be adjust our
        work strategies to address our client’s challenges`,
        `Diversity: We believe in collectively working together with different field experts to meet
        the needs of our clients and to help our Company win.`,
        `Team Building/work: We are committed to provide a team supporting and growth system. We believe that working
        together as a team can enable us achieve over and above our goals in our clients’ satisfaction.`,
        `Deliver Quality Results: We are committed to work exceptionally in delivering unsurpassed services to our clients in time.`,
        `Accountability: We are accountable for our work, our employees, our clients and our company.`,
        `Creativity and innovation: As solution providers, we never give up seeking creative ways to solve
        tough problems. We always strive for excellence and are unconstrained by the way things have always been done.`,
        `Performance Improvement: We are committed to challenge ourselves by continuously improving our performance
        and pushing through our comfort barriers.`,
        
        // `To maintain excellent knowledge on the interaction between the community and environment,
        // practicing sound financial and project management that produces optimal results.`,
        // 'To exercise effective communication with our clients and stakeholders to our projects.',
        // 'Lies And Damn Lies About business',
        // `To successfully influence people, organizations, systems and processes towards
        // environmental and social preeminent practice.`,
      ],
    },
  ];
  const [selectedKey, setSelectedKey] = useState(tabs[0].key);

  const handleTabSelect = (key) => setSelectedKey(key);

  const renderTabContent = () => {
    const selectedTab = tabs.filter(
      (t) => t.key === selectedKey,
    )[0];
    return (
      <>
        {selectedTab.paragraphs.map((p) => (<p>{p}</p>))}
        {selectedTab.hasBullets && (
          <div className="mt--30">
            <h4>{selectedTab.bulletHeader}</h4>
            <ul>
              {selectedTab.bullets.map((b) => (
                <li>
                  <i className="fa fa-check" />
                  {b}
                </li>
              ))}
            </ul>
          </div>
        )}
      </>
    );
  };

  return (
    <HomeSection
      title="About"
      subheader="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut."
      className="parallax"
      showTitle={false}
      style={parallaxStyle}
    >
      <div className="parallax-inner">
        <HomeSection showTitle={false}>
          <Container id="about-section">
            <Row className="layout horizontal wrap">
              <Col className="col-lg-5">
                <div className="thumbnail">
                  <img src={aboutUrl} className="max-width" alt="About Images" />
                </div>
              </Col>
              <Col className="col-lg-7 layout vertical wrap">
                <div className="section-title" style={{ marginTop: '-15px' }}>
                  <h2 className="title">About</h2>
                  <p className="description">
                    Established in 2014, GESCH Consult ltd has progressively grown to be a
                    momentous player in Uganda’s environmental and social industry in Uganda
                    and Africa at large. GESCH Consult is an interdisciplinary team of highly
                    skilled professionals and is made up of specialist consultants from
                    diversity of environmental and social fields, and in conjunction with
                    our range of local and international network partners who provide GESCH
                    clients to an unprecedented body of knowledge and expertise
                  </p>
                </div>
                <Row className="mt--30">
                  <div className="tabs-area">
                    <Container>
                      <Row>
                        <Col className="col-lg-12">
                          <div className="tabs" data-tabs="true">
                            <ul className="tabs_header" style={{ display: 'flex', margin: '0 -20px', flexWrap: 'wrap' }}>
                              {tabs && tabs.length && tabs.map((t) => (
                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                <li
                                  key={t.key}
                                  className={`tab_header ${t.key === selectedKey ? 'selected' : ''}`}
                                  role="tab"
                                  id={t.key}
                                  onClick={() => handleTabSelect(t.key)}
                                >
                                  {t.title}
                                </li>
                              ))}
                            </ul>
                            <div className="tabs_panel" role="tabpanel">
                              <div className="single-tab-content">
                                {renderTabContent()}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Row>
              </Col>
            </Row>
            {children}
          </Container>
        </HomeSection>
      </div>
    </HomeSection>
  );
};

AboutIntro.propTypes = {
  children: PropTypes.node,
};

AboutIntro.defaultProps = {
  children: <span />,
};

export default AboutIntro;
