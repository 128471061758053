/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';

import AboutIntro from '../about/AboutIntro';
import Row from '../common/Row';
import Col from '../common/Column';

export default function AboutSection() {
  return (
    <AboutIntro>
      <Row>
        <Col className="col-12 layout horizontal center-justified mt--30">
          <Link className="theme-btn" to="/about">Meet The Team</Link>
        </Col>
      </Row>
    </AboutIntro>
  );
}
