import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

// components
import Footer from './common/Footer';
import Header from './common/Header';
import Routes from './Routes';

import store from '../redux/store';

function App() {
  return (
    <Provider store={store}>
      <ToastContainer />
      <Router>
        <Header />
        <Routes />
        <Footer />
      </Router>
    </Provider>
  );
}

export default App;
