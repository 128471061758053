export const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

export const getRandom = (arr) => {
  const randInt = getRandomInt(arr.length);
  return arr[randInt];
};

// utility function to add 2 numbers
// use the function while adding numbers
// display the result
// tests for function
export const add = (a, b) => {
  // validating
  const sum = a + b;
  return sum;
}
