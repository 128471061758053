import axiosInstance from '../../utils/axios';

import * as actions from './actionCreators';

const getQuotes = () => async (dispatch) => {
  dispatch(actions.getQuotesInit());
  try {
    const res = await axiosInstance.get('/api/quotes/');
    dispatch(actions.getQuotesSuccess(res.data && res.data.results));
  } catch (error) {
    if (error.response) {
      dispatch(actions.getQuotesError(error.response.data));
    }
  }
};

export default getQuotes;
