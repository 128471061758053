import { toast } from 'react-toastify';
import axiosInstance from '../../utils/axios';

import {
  createMessageInit,
  createMessageSuccess,
  createMessageError,
} from './actionCreators';

const createMessage = (message) => async (dispatch) => {
  dispatch(createMessageInit());
  try {
    await axiosInstance.post('/api/messages/', JSON.stringify(message));
    toast.success("Email sent successfully!")
    dispatch(createMessageSuccess());
  } catch (error) {
    console.log('error: ', error);
    if (error.response) {
      dispatch(createMessageError(error.response.data));
    }
  }
};

export default createMessage;
