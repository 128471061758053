import axiosInstance from '../../utils/axios';

import * as actions from './actionCreators';

const getProjects = () => async (dispatch) => {
  dispatch(actions.getProjectsInit());
  try {
    const res = await axiosInstance.get('/api/projects/');
    dispatch(actions.getProjectsSuccess(res.data && res.data.results));
  } catch (error) {
    if (error.response) {
      dispatch(actions.getProjectsError(error.response.data));
    }
  }
};

export default getProjects;
