import * as actionTypes from './actionTypes';

export const getServicesInit = () => ({
  type: actionTypes.GET_SERVICES_INIT,
});

export const getServicesSuccess = (services) => ({
  type: actionTypes.GET_SERVICES_SUCCESS,
  payload: services,
});

export const getServicesError = (error) => ({
  type: actionTypes.GET_SERVICES_ERROR,
  payload: error,
});

export const getServiceInit = () => ({
  type: actionTypes.GET_SERVICE_INIT,
});

export const getServiceSuccess = (service) => ({
  type: actionTypes.GET_SERVICE_SUCCESS,
  payload: service,
});

export const getServiceError = (error) => ({
  type: actionTypes.GET_SERVICE_ERROR,
  payload: error,
});

export const getQuotesInit = () => ({
  type: actionTypes.GET_QUOTES_INIT,
});

export const getQuotesSuccess = (quotes) => ({
  type: actionTypes.GET_QUOTES_SUCCESS,
  payload: quotes,
});

export const getQuotesError = (error) => ({
  type: actionTypes.GET_QUOTES_ERROR,
  payload: error,
});

export const getProjectsInit = () => ({
  type: actionTypes.GET_PROJECTS_INIT,
});

export const getProjectsSuccess = (projects) => ({
  type: actionTypes.GET_PROJECTS_SUCCESS,
  payload: projects,
});

export const getProjectsError = (error) => ({
  type: actionTypes.GET_PROJECTS_ERROR,
  payload: error,
});

export const createMessageInit = () => ({
  type: actionTypes.CREATE_MESSAGE_INIT,
});

export const createMessageSuccess = () => ({
  type: actionTypes.CREATE_MESSAGE_SUCCESS,
});

export const createMessageError = (error) => ({
  type: actionTypes.CREATE_MESSAGE_ERROR,
  payload: error,
});

