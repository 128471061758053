import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// css
import './services.scss';

// redux
import STATUS from '../../redux/actions/constants';

// components
import Container from '../common/Container';
import Row from '../common/Row';
import Col from '../common/Column';
import PageBanner from '../common/PageBanner';
import Section from '../common/Section';
import images from '../../images/banners/index';
import ContainerLoader from '../common/ContainerLoader';
import Messenger from '../common/Messenger';

export default function ServicePage() {
  const { serviceSlug } = useParams();

  const { status } = useSelector((state) => state.services);
  const service = useSelector(
    (state) => state.services.services.filter((s) => s.slug === serviceSlug),
  )[0];

  let bannerTag = images.esmp;
  let title = '...';
  if (service) {
    bannerTag = images[service.banner_tag];
    title = service.name.toLowerCase();
  }

  return (
    <div id="service-page">
      <PageBanner title={title} />
      <Section>
        {status === STATUS.LOADING && (<ContainerLoader />)}
        {status !== STATUS.LOADING && !service && (
          <Messenger message="Uh-Oh. How did we get here." />
        )}
        {status !== STATUS.LOADING && service && (
          <Container>
            <Row>
              <Col className="col-lg-12">
                <div className="inner pb--80">
                  <Row>
                    <Col className="col-lg-6 col-12">
                      <div className="thumb">
                        <img src={bannerTag} alt="" />
                      </div>
                    </Col>
                    <Col className="col-lg-6 col-12">
                      <div className="layout wrap vertical">
                        <div className="service-content-wrapper layout vertical">
                          <div className="service-content">
                            <h4 className="service-title dark wood-border">{service.name}</h4>
                            <p>
                              {service.description}
                            </p>
                            <h4 className="bulletSubHeading">
                              {service.bullet_sub_header}
                            </h4>
                            <ul>
                              {service.bullets.length > 0
                                && service.bullets.map((bullet) => <li className="bigger">{bullet.description}</li>)}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </Section>
    </div>
  );
}
