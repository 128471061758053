import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Col from '../common/Column';

import images from '../../images/projects/index';

const Project = ({ project }) => {
  const bannerUrl = images[project.banner_tag];

  return (
    <Col className="col-lg-3 col-md-6 col-12">
      <div className="project">
        <div className="thumbnail">
          <Link to="/projects">
            <img src={bannerUrl} alt="" className="max-width" />
          </Link>
        </div>
        <div className="content">
          <p className="portfoliotype">{project.client && project.client.name}</p>
          <h4 className="title">
            <Link to="/projects">{project.name}</Link>
          </h4>
          {project.extension && <h5 className="title">{project.extension}</h5>}
          <p className="portfoliotype">{`${project.service} ${project.extension ? project.extension : ''}`}</p>
          <div className="portfolio-btn">
            <Link to="/projects" className="rn-btn text-white">
              Read More
            </Link>
          </div>
        </div>
      </div>
    </Col>
  );
};

Project.propTypes = {
  project: PropTypes.object.isRequired,
};

export default Project;
