import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';

import Home from './home/Home';
import ServicesContainer from './services/ServicesContainer';
import ProjectsContainer from './projects/ProjectsContainer';
import AboutPage from './about/AboutPage';
import Contact from './contact/Contact';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/services" component={ServicesContainer} />
      <Route path="/projects" component={ProjectsContainer} />
      <Route path="/about" component={AboutPage} />
      <Route path="/contact" component={Contact} />
    </Switch>
  );
}
