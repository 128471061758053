import React, { useState } from 'react';
import PropTypes from 'prop-types';

const IS_VALID = 'is-valid';
const IS_INVALID = 'is-invalid';
const HAS_SUCCESS = 'has-success';
const HAS_DANGER = 'has-danger';

const InputFormGroup = ({
  type,
  name,
  value,
  label,
  handleChange,
  handleBlur,
  valid,
  touched,
  invalidFeedback
}) => {

  return (
    <div
      className={
        `form-group${touched && valid ? ` ${HAS_SUCCESS}` : ''}${touched && !valid ? ` ${HAS_DANGER}` : ''}`
      }
    >
      <label className="form-control-label">{label}</label>
      <input
        type="text"
        className={
          `form-control${touched && valid ? ` ${IS_VALID}` : ''}${touched && !valid ? ` ${IS_INVALID}` : ''}`
        }
        name={name}
        value={value}
        onChange={(e) => handleChange(e)}
        onBlur={(e) => handleBlur(e)}
        autoComplete="off"
      />
      {touched && !valid && <div className="invalid-feedback">{invalidFeedback}</div>}
    </div>
  )
};

InputFormGroup.props = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  invalidFeedback: PropTypes.string,
  valid: PropTypes.bool,
  touched: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

InputFormGroup.defaultProps = {
  type: "text",
  invalidFeedback: 'Field is required',
  valid: false,
  touched: false,
  value: '',
}

export default InputFormGroup;
