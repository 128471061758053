import React from 'react';

import CarouselContainer from '../carousels/CarouselContainer';
import ServicesSection from './ServicesSection';
import ProjectSection from './ProjectSection';
import AboutSection from './AboutSection';
import ClientSection from './ClientSection';

// css
import './home.scss';

export default function Home() {
  return (
    <div id="home">
      <CarouselContainer />
      <ServicesSection />
      <ProjectSection />
      <AboutSection />
      <ClientSection />
    </div>
  );
}
