import React from 'react';
import { useSelector } from 'react-redux';

// redux
import STATUS from '../../redux/actions/constants';
import bannerImage from '../../images/works.jpg';

// components
import Section from '../common/Section';
import ContainerLoader from '../common/ContainerLoader';
import Messenger from '../common/Messenger';
import PageBanner from '../common/PageBanner';
import ProjectList from './ProjectList';

export default function Projects() {
  const { status, projects } = useSelector((state) => state.projects);

  return (
    <div>
      <PageBanner title="Projects" bannerImageUrl={bannerImage} />
      <Section className="ptb--120">
        {status === STATUS.LOADING && (<ContainerLoader />)}
        {status !== STATUS.LOADING && projects && projects.length === 0 && (
          <Messenger message="Check back later when we have populated the list of projects." />
        )}
        {status !== STATUS.LOADING && projects && projects.length > 0 && (
          <ProjectList projects={projects} />
        )}
      </Section>
    </div>
  );
}
