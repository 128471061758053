import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import img from '../../images/5.png';

function HeaderContentTwo({ image, quote }) {
  // state
  const [className, setClassName] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setClassName('animate-visible');
    }, 1000);
    return () => {
      setClassName('');
    };
  }, []);

  return (
    <div className={className} id="header-content-two">
      <h2 className="delay1 headline">
        <b>{quote.tagline}</b>
      </h2>
      <p className="delay2 tagline">
        {quote.follow_up}
      </p>
      <div className="delay3 actions">
        <a href="/" className="btn btn-default">See how it works...</a>
        <img src={image} alt="img" />
      </div>
    </div>
  );
}

HeaderContentTwo.propTypes = {
  image: PropTypes.node,
  quote: PropTypes.shape({
    tagline: PropTypes.string.isRequired,
    follow_up: PropTypes.string.isRequired,
  }).isRequired,
};

HeaderContentTwo.defaultProps = {
  image: img,
};

export default HeaderContentTwo;
