import React from 'react';

// css
import '../common/css/common.scss';
import './carousel.scss';

// components
import Section from '../common/Section';
import Carousel from './Carousel';
import HeaderContentContainer from './HeaderContentContainer';

export default function CarouselContainer() {
  return (
    <Section className="header-section">
      <Carousel />
      <HeaderContentContainer />
    </Section>
  );
}
