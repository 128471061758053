import axiosInstance from '../../utils/axios';

import * as actions from './actionCreators';

export const getServices = () => async (dispatch) => {
  dispatch(actions.getServicesInit());
  try {
    const res = await axiosInstance.get('/api/services/');
    dispatch(actions.getServicesSuccess(res.data && res.data.results));
  } catch (error) {
    if (error.response) {
      dispatch(actions.getServicesError(error.response.data));
    }
  }
};

export const getService = (serviceId) => async (dispatch) => {
  dispatch(actions.getServiceInit());
  try {
    const res = await axiosInstance.get(`/api/services/${serviceId}/`);
    dispatch(actions.getServiceSuccess(res.data && res.data));
  } catch (error) {
    if (error.response) {
      dispatch(actions.getServiceError(error.response.data));
    }
  }
};
