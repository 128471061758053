// SERVICES
export const GET_SERVICES_INIT = 'GET_SERVICES_INIT';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_ERROR = 'GET_SERVICES_ERROR';

export const GET_SERVICE_INIT = 'GET_SERVICE_INIT';
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS';
export const GET_SERVICE_ERROR = 'GET_SERVICE_ERROR';

// QUOTES
export const GET_QUOTES_INIT = 'GET_QUOTES_INIT';
export const GET_QUOTES_SUCCESS = 'GET_QUOTES_SUCCESS';
export const GET_QUOTES_ERROR = 'GET_QUOTES_ERROR';

// PROJECTS
export const GET_PROJECTS_INIT = 'GET_PROJECTS_INIT';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_ERROR = 'GET_PROJECTS_ERROR';

// Messages
export const CREATE_MESSAGE_INIT = 'CREATE_MESSAGE_INIT';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_ERROR = 'CREATE_MESSAGE_ERROR';
