import React from 'react';
import { Link } from 'react-router-dom';

import Row from './Row';
import Col from './Column';

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-wrapper">
        <Row className="row--0 layout end">
          <Col className="col-lg-6">
            <div className="footer-left">
              <div className="inner">
                <span>Expertise, Quality & Timely Delivery</span>
                <h2>
                  Let&apos;s get
                  <br />
                  to work
                </h2>
                <Link className="theme-btn" to="/contact">Contact Us</Link>
              </div>
            </div>
          </Col>
          <Col className="col-lg-6">
            <div className="footer-right" data-black-overlay="6">
              <Row className="row">
                <Col className="col-lg-6 col-sm-6 col-12">
                  <div className="footer-link">
                    <h4>Quick Links</h4>
                    <ul className="ft-links">
                      <li>
                        <Link to="/services"> Services</Link>
                      </li>
                      <li>
                        <Link to="/projects"> Projects</Link>
                      </li>
                      <li>
                        <Link to="/about"> About</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col className="col-lg-6 col-sm-6 col-12">
                  <div className="footer-link">
                    <h4>Social Media</h4>
                    <ul className="social-share layout horizontal start-justified mt--15">
                      <li>
                        <a target="_blank" rel="noreferrer" href="https:www.facebook.com/geschconsult">
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a target="_blank" rel="noreferrer" href="https:www.twitter.com/geschconsult">
                          <i className="fa fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a target="_blank" rel="noreferrer" href="https:www.instagram.com/geschconsult">
                          <i className="fa fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a target="_blank" rel="noreferrer" href="https:www.likendin.com/geschconsult">
                          <i className="fa fa-linkedin" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col className="col-lg-12">
                  <div className="copyright-text">
                    <p className="dust">
                      Copyright © 2021. All Rights Reserved. sibostack.com
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
