import React from 'react';
import PropTypes from 'prop-types';

function Container({ children, className, id }) {
  return (
    <div className={`container ${className}`} id={id}>
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
};

Container.defaultProps = {
  children: <span />,
  className: '',
  id: '',
};

export default Container;
