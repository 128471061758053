import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// components
import Projects from './Projects';

// redux
import getProjects from '../../redux/actions/projectActions';

export default function ServicesContainer() {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);
  return (
    <Switch>
      <Route path={path} component={Projects} />
    </Switch>
  );
}
