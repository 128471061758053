import React from 'react';
import PropTypes from 'prop-types';

function Row({ className, children }) {
  return (
    <div className={`row ${className}`}>
      {children}
    </div>
  );
}

Row.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Row.defaultProps = {
  children: <span />,
  className: 'row',
};

export default Row;
