import React from 'react';

// components
import Loader from 'react-loader-spinner';

import Col from './Column';
import Container from './Container';
import Row from './Row';

export default function ContainerLoader() {
  return (
    <Container>
      <Row>
        <Col>
          <div className="layout horizontal center-justified">
            <Loader
              type="BallTriangle"
              color="#f9004d"
              height={100}
              width={100}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
