import React from 'react';
import PropTypes from 'prop-types';

import { add } from '../../utils';



function Column({ children, className }) {
  return (
    <div className={className}>
      {children}
    </div>
  );
}

Column.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Column.defaultProps = {
  children: <span />,
  className: 'col',
};

export default Column;
