import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

// components
import Col from '../common/Column';
import images from '../../images/banners/index';

const ServiceItem = ({ service }) => {
  const bannerUrl = images[service.banner_tag];

  const bannerStyle = { backgroundImage: `url(${bannerUrl})` };

  return (
    <Col className="col-md-4 mb--30">
      <Link to={`services/${service.slug}`} className="service-link">
        <div className="service layout wrap vertical">
          <div className="service-banner" style={bannerStyle} />
          <div className="service-content-wrapper layout vertical">
            <div className="layout vertical center-center icon">
              <i className={`fa ${service.icon}`} />
            </div>
            <div className="service-content">
              <h4 className="dark">{service.name}</h4>
              <p>
                {service.short_description}
              </p>
            </div>
          </div>
        </div>
      </Link>
    </Col>
  );
};

ServiceItem.propTypes = {
  service: PropTypes.object.isRequired,
};

export default ServiceItem;
