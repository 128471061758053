import image1 from './1.png';
import image2 from './2.png';
import image4 from './4.png';
import image5 from './5.png';

export default {
  image1,
  image2,
  image4,
  image5,
};
