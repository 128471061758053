import React from 'react';
import PropTypes from 'prop-types';

// components
import ServiceItem from './ServiceItem';

const ServiceList = ({ services }) => services.map((service) => (
  <ServiceItem key={service.id} service={service} />
));

ServiceList.propTypes = {
  services: PropTypes.array.isRequired,
};

export default ServiceList;
