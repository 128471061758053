import * as actionTypes from '../actions/actionTypes';
import STATUS from '../actions/constants';

const initialState = {
  status: STATUS.IDLE,
  error: null,
  quotes: [],
};

const quotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_QUOTES_INIT:
      return {
        ...state,
        status: STATUS.LOADING,
        error: null,
      };
    case actionTypes.GET_QUOTES_SUCCESS:
      return {
        ...state,
        status: STATUS.SUCCEEDED,
        quotes: action.payload,
        error: null,
      };
    case actionTypes.GET_QUOTES_ERROR:
      return {
        ...state,
        status: STATUS.FAILED,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default quotesReducer;
