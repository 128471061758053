import React, {
  useState,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

// utils
import { getRandom } from '../../utils/index';
import IMAGES from '../../images/index';

// redux
import getQuotes from '../../redux/actions/quoteActions';
import STATUS from '../../redux/actions/constants';

// components
import HeaderContentOne from './HeaderContentOne';
import HeaderContentTwo from './HeaderContentTwo';
import HeaderContentThree from './HeaderContentThree';
import ContainerLoader from '../common/ContainerLoader';
import Messenger from '../common/Messenger';

function HeaderContentContainer() {
  // state
  const [iter, setIter] = useState(0);
  const [quoteIndex, setQuoteIndex] = useState(0);

  // quotes
  const { quotes, status } = useSelector((state) => state.quotes);

  const stepNextQuote = () => {
    if (quoteIndex >= quotes.length - 1) setQuoteIndex(0);
    else setQuoteIndex(quoteIndex + 1);
  };

  const getRandomImage = () => {
    const imgs = Object.keys(IMAGES);
    const img = getRandom(imgs);
    return IMAGES[img];
  };

  const getContent = () => {
    const quote = quotes[quoteIndex];
    if (iter === 0) return <HeaderContentOne image={getRandomImage()} quote={quote} />;
    if (iter === 1) return <HeaderContentTwo image={getRandomImage()} quote={quote} />;
    if (iter === 2) return <HeaderContentThree quote={quote} />;
    return '';
  };

  // effects
  const dispatch = useDispatch();
  useEffect(() => {
    const timer = setInterval(() => {
      stepNextQuote();
      if (iter >= 2) {
        return setIter(0);
      }
      return setIter(iter + 1);
    }, 10000);
    // clearing interval
    return () => clearInterval(timer);
  });
  useEffect(() => {
    dispatch(getQuotes());
  }, []);

  return (
    <>
      {status === STATUS.LOADING && (<ContainerLoader />)}
      {status !== STATUS.LOADING && quotes && quotes.length === 0 && (
        <Messenger message="Environment Preserved A Future Secured" />
      )}
      {status !== STATUS.LOADING && quotes && quotes.length > 0 && (
        <div className="container header-content">
          {getContent()}
        </div>
      )}
    </>
  );
}

export default HeaderContentContainer;
