import React from 'react';
import PropTypes from 'prop-types';

import bannerImage from '../../images/forest.jpg';

const PageBanner = ({ title, bannerImageUrl, subtitle }) => (
  <div className="page-banner ptb--120" style={{ backgroundImage: `url(${bannerImageUrl})` }}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="page-banner-content layout vertical center-center pt--100">
            <h2 className="title text-center">
              {title}
            </h2>
            <p>{subtitle}</p>
            <hr className="lines" />
          </div>
        </div>
      </div>
    </div>
    <div className="overlay" />
  </div>
);

PageBanner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  bannerImageUrl: PropTypes.string,
};

PageBanner.defaultProps = {
  bannerImageUrl: bannerImage,
  subtitle: '',
};

export default PageBanner;
