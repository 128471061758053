import React from 'react';
import { useSelector } from 'react-redux';

// redux
import STATUS from '../../redux/actions/constants';

// css
import './services.scss';

// components
import Section from '../common/Section';
import Container from '../common/Container';
import Row from '../common/Row';
import Col from '../common/Column';
import PageBanner from '../common/PageBanner';
import ServiceList from './ServiceList';
import ContainerLoader from '../common/ContainerLoader';
import Messenger from '../common/Messenger';

export default function Services() {
  const { status, services } = useSelector((state) => state.services);

  return (
    <div id="services-page">
      <PageBanner title="Services" />
      <Section className="ptb--120">
        <Container>
          <Row>
            <Col className="col-lg-12">
              <div className="section-title text-center mb--30">
                <h2>Environment Preserved A Future Secured</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className="pb--120">
        {status === STATUS.LOADING && (<ContainerLoader />)}
        {status !== STATUS.LOADING && services && services.length === 0 && (
          <Messenger message="Bear With Us As We Populate Our List Of Servives" />
        )}
        {status !== STATUS.LOADING && services && services.length > 0 && (
          <Container>
            <Row>
              <ServiceList services={services} />
            </Row>
          </Container>
        )}
      </Section>
    </div>
  );
}
