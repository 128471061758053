import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// components
import ServicePage from './ServicePage';
import Services from './Services';

// redux
import { getServices } from '../../redux/actions/serviceActions';

export default function ServicesContainer() {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getServices());
  }, []);
  return (
    <Switch>
      <Route exact path={path} component={Services} />
      <Route path={`${path}/:serviceSlug`} component={ServicePage} />
    </Switch>
  );
}
