import React from 'react';

// components
import AboutIntro from './AboutIntro';

export default function AboutPage() {
  return (
    <>
      <AboutIntro />
    </>
  );
}
