import { combineReducers } from 'redux';

import serviceReducer from './serviceReducer';
import quotesReducer from './quotesReducer';
import projectsReducer from './projectsReducer';
import messagesReducer from './messagesReducer';

export default combineReducers({
  services: serviceReducer,
  quotes: quotesReducer,
  projects: projectsReducer,
  messages: messagesReducer,
});
