import React from 'react';

export default function Carousel() {
  return (
    <div className="carousel-wrapper">
      <div
        id="carousel-container"
        className="carousel slide carousel-fade"
        data-ride="carousel"
        data-interval="10000"
      >
        <ol className="carousel-indicators">
          <li data-target="#carousel-container" data-slide-to="0" className="active" />
          <li data-target="#carousel-container" data-slide-to="1" />
          <li data-target="#carousel-container" data-slide-to="2" />
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active" id="slide1" />
          <div className="carousel-item" id="slide2" />
          <div className="carousel-item" id="slide3" />
        </div>
      </div>
      <div className="main-carousel-mask" />
    </div>
  );
}
