/* eslint-disable camelcase */

import dark from './dark.jpg';
import eco from './eco.jpg';
import express from './express.jpg';
import landscape from './landscape.jpg';
import leafdrops from './leafdrops.jpg';
import raindrops from './raindrops.jpg';
import sunrise from './sunrise.jpg';
import tent from './tent.jpg';
import forest1 from './forest1.jpg';

export default {
  dark,
  eco,
  express,
  landscape,
  leafdrops,
  raindrops,
  sunrise,
  tent,
  forest1,
};
