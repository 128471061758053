import React from 'react';
import PropTypes from 'prop-types';

// components
import Col from './Column';
import Container from './Container';
import Row from './Row';

const Messenger = ({ message }) => (
  <Container>
    <Row>
      <Col>
        <div className="layout horizontal center-justified">
          <h4 className="charcoal">
            {message}
          </h4>
        </div>
      </Col>
    </Row>
  </Container>
);

Messenger.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Messenger;
