import React from 'react';
import PropTypes from 'prop-types';

const ContactCard = ({ title, p1, p2, children }) => (
  <div className="address">
    <div className="icon layout horizontal center-center">
      {children}
    </div>
    <div className="inner">
      <h4 className="title">
        {title}
      </h4>
      <p>{p1}</p>
      <p>{p2}</p>
    </div>
  </div>
);

ContactCard.props = {
  title: PropTypes.string.isRequired,
  p1: PropTypes.string.isRequired,
  p2: PropTypes.string,
  children: PropTypes.node,
};

ContactCard.defaultProps = {
  p2: '',
  children: <span />,
};

export default ContactCard;
